// extracted by mini-css-extract-plugin
export var bookIntroSection = "index-module--book-intro-section--9DzJB";
export var bookIntroBlock = "index-module--book-intro-block--W3Lyg";
export var bookCoverImage = "index-module--book-cover-image--vIe1s";
export var pitchTextBlock = "index-module--pitch-text-block--wZMHp";
export var pitch = "index-module--pitch--YEYWq";
export var buySection = "index-module--buy-section--P6Fvw";
export var buyBlock = "index-module--buy-block--ppiaN";
export var buyHeading = "index-module--buy-heading--yWVP4";
export var buttonBlock = "index-module--button-block--UIZHQ";
export var button = "index-module--button--Kx3RA";
export var praiseBlock = "index-module--praise-block--9pPAj";
export var attribution = "index-module--attribution--gToCa";
export var miniAboutSection = "index-module--mini-about-section--vHbZF";
export var miniAboutBlock = "index-module--mini-about-block--NroLc";
export var aboutImage = "index-module--about-image--YLwmL";
export var aboutImageCredit = "index-module--about-image-credit--+huIQ";
export var aboutTextBlock = "index-module--about-text-block--wGI8v";